import React from "react";
import Layout from "../components/Layout";
import type { HeadFC } from "gatsby";
import { Box, Container, styled, Typography } from "@mui/material";

import { SEO } from "../components/SEO";

const SectionTitle = styled(Typography)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.custom.dark}`,
  fontSize: "20px",
  paddingBottom: "8px",
  marginBottom: "24px",
  textTransform: "uppercase",
  letterSpacing: "3px",
  fontWeight: "500",
}));

const CookiesPolicies = () => {
  return (
    <Layout>
      <Box bgcolor="rgb(250, 158, 18)" py={6} px={4}>
        <SectionTitle>POLÍTICA DE COOKIES</SectionTitle>
        <Box
          bgcolor="white"
          position="relative"
          px={6}
          py={6}
          zIndex="2"
          width="100%"
        >
          <Typography variant="body1" pb={3}>
            En cumplimiento con lo dispuesto en el artículo 22.2 de la Ley
            34/2002, de 11 de julio, de Servicios de la Sociedad de la
            Información y de Comercio Electrónico, esta página web le informa,
            en esta sección, sobre la política de recogida y tratamiento de
            cookies.
          </Typography>
          <Typography variant="h3" color="black" pb={3}>
            ¿QUÉ SON LAS COOKIES?
          </Typography>
          <Typography variant="body1" pb={3}>
            Una cookie es un fichero que se descarga en su ordenador al acceder
            a determinadas páginas web. Las cookies permiten a una página web,
            entre otras cosas, almacenar y recuperar información sobre los
            hábitos de navegación de un usuario o de su equipo y, dependiendo de
            la información que contengan y de la forma en que utilice su equipo,
            pueden utilizarse para reconocer al usuario.
          </Typography>
          <Typography variant="h3" color="black" pb={3}>
            ¿QUÉ TIPOS DE COOKIES UTILIZA ESTA PÁGINA WEB?
          </Typography>
          <Typography variant="body1" pb={3}>
            Esta página web utiliza los siguientes tipos de cookies:
            <br />
            <br />
            Cookies de análisis: Son aquéllas que bien tratadas por nosotros o
            por terceros, nos permiten cuantificar el número de usuarios y así
            realizar la medición y análisis estadístico de la utilización que
            hacen los usuarios del servicio ofertado. Para ello se analiza su
            navegación en nuestra página web con el fin de mejorar la oferta de
            productos o servicios que le ofrecemos.
            <br />
            <br />
            Cookies técnicas: Son aquellas que permiten al usuario la navegación
            a través de los formularios de la web.
            <br />
            <br />
            Cookies publicitarias: Son aquéllas que, bien tratadas por esta web
            o por terceros, permiten gestionar de la forma más eficaz posible la
            oferta de los espacios publicitarios que hay en la página web,
            adecuando el contenido del anuncio al contenido del servicio
            solicitado o al uso que realice de nuestra página web. Para ello
            podemos analizar sus hábitos de navegación en Internet y podemos
            mostrarle publicidad relacionada con su perfil de navegación.
          </Typography>
          <Typography variant="h3" color="black" pb={3}>
            DESACTIVAR LAS COOKIES
          </Typography>
          <Typography variant="body1">
            Puede usted permitir, bloquear o eliminar las cookies instaladas en
            su equipo mediante la configuración de las opciones del navegador
            instalado en su ordenador.
            <br />
            <br />
            En la mayoría de los navegadores web se ofrece la posibilidad de
            permitir, bloquear o eliminar las cookies instaladas en su equipo.
            <br />
            <br />A continuación puede acceder a la configuración de los
            navegadores webs más frecuentes para aceptar, instalar o desactivar
            las cookies:
            <br />
            <ul>
              <li>
                <span style={{ fontWeight: "400" }}>Chrome&nbsp;[</span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://support.google.com/chrome/answer/95647?hl=es"
                >
                  <span style={{ fontWeight: "400" }}>
                    https://support.google.com/chrome/answer/95647?hl=es
                  </span>
                </a>
                <span style={{ fontWeight: "400" }}>].&nbsp;</span>
              </li>
              <li>
                <span style={{ fontWeight: "400" }}>Explorer&nbsp;[</span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11"
                >
                  <span style={{ fontWeight: "400" }}>
                    https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11
                  </span>
                </a>
                <span style={{ fontWeight: "400" }}>].&nbsp;</span>
              </li>
              <li>
                <span style={{ fontWeight: "400" }}>Firefox&nbsp;[</span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias"
                >
                  <span style={{ fontWeight: "400" }}>
                    https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias
                  </span>
                </a>
                <span style={{ fontWeight: "400" }}>].&nbsp;</span>
              </li>
              <li>
                <span style={{ fontWeight: "400" }}>Safari&nbsp;[</span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://support.apple.com/es-es/guide/safari/sfri11471/mac"
                >
                  <span style={{ fontWeight: "400" }}>
                    https://support.apple.com/es-es/guide/safari/sfri11471/mac
                  </span>
                </a>
                <span style={{ fontWeight: "400" }}>].&nbsp;</span>
              </li>
            </ul>
            <br />
            Asimismo, le recordamos que puede utilizar el modo de navegación
            privada en su navegador, por lo que su navegador dejará de guardar
            el historial de navegación, las cookies y otra información del sitio
            web.
          </Typography>
          <Typography variant="h3" color="black" py={3}>
            COOKIES DE TERCEROS
          </Typography>
          <Typography variant="body1" pb={3}>
            En concreto, usamos los servicios de Google Adsense y de Google
            Analytics para nuestras estadísticas y publicidad. Algunas cookies
            son esenciales para el funcionamiento del sitio, por ejemplo el
            buscador incorporado.
          </Typography>
          <Typography variant="h3" color="black" pb={3}>
            ADVERTENCIA SOBRE ELIMINAR COOKIES.
          </Typography>
          <Typography variant="body1">
            Usted puede eliminar y bloquear todas las cookies de este sitio,
            pero parte del sitio no funcionará o la calidad de la página web
            puede verse afectada.
            <br />
            <br />
            Si tiene cualquier duda acerca de nuestra política de cookies, puede
            contactar con esta página web a través de nuestros canales de
            Contacto.
          </Typography>
        </Box>
      </Box>
    </Layout>
  );
};

export default CookiesPolicies;

export const Head: HeadFC = () => (
  <SEO title="Politicas de Cookies - Fibexty"></SEO>
);
